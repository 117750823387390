import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BreadcrumbStyles = styled.div`
  width: 100vw;
  font-size: 14px;
  color: #666;
  background: #f2f2f2;
  padding: 20px 0;
  box-sizing: border-box;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    a {
      color: rgb(102, 102, 102);
      font-family: 'Montserrat', 'Nunito Sans', -apple-system,
        BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 14px;
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      transition-delay: 0s;
      transition-duration: 0.3s;
      transition-property: all;
      transition-timing-function: ease-in-out;
    }
    &::after {
      content: '|';
      opacity: 0.5;
      padding: 0 20px;
      cursor: default;
    }
    &:last-of-type {
      a {
        color: var(--accent);
      }
    }
    &:last-of-type::after {
      content: '';
    }
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    opacity: 1;
    color: #272343;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export default function Breadcrumb({ children, title }) {
  return (
    <BreadcrumbStyles>
      <div className="container flex">
        <ul>{children}</ul>

        <h2>{title}</h2>
      </div>
    </BreadcrumbStyles>
  );
}

Breadcrumb.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};
