import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SidebarLinksStyles = styled.div`
  background: #414141;
  padding: 15px;
  box-sizing: border-box;

  ul {
    border-bottom-color: rgb(85, 85, 85);
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(85, 85, 85);
    border-left-style: none;
    border-left-width: 0px;
    border-right-color: rgb(85, 85, 85);
    border-right-style: none;
    border-right-width: 0px;
    border-top-color: rgb(85, 85, 85);
    border-top-style: none;
    border-top-width: 0px;
    box-sizing: border-box;
    color: rgb(85, 85, 85);
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-optical-sizing: auto;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 400;
    line-height: 24px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: disc;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgb(85, 85, 85);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-rendering: optimizelegibility;
    vertical-align: baseline;
    -moz-osx-font-smoothing: grayscale;
  }

  li {
    border-bottom-color: rgb(85, 85, 85);
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(85, 85, 85);
    border-left-style: none;
    border-left-width: 0px;
    border-right-color: rgb(85, 85, 85);
    border-right-style: none;
    border-right-width: 0px;
    border-top-color: rgb(85, 85, 85);
    border-top-style: none;
    border-top-width: 0px;
    box-sizing: border-box;
    color: rgb(85, 85, 85);
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-optical-sizing: auto;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 400;
    line-height: 24px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgb(85, 85, 85);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 12px;
    text-rendering: optimizelegibility;
    vertical-align: baseline;
    -moz-osx-font-smoothing: grayscale;

    a {
      background-color: rgba(0, 0, 0, 0);
      border: 0px none rgb(255, 255, 255);
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      display: block;
      font: 500 14px / 24px 'Montserrat', sans-serif;
      list-style-position: outside;
      margin: 0px;
      outline: rgb(255, 255, 255) none 0px;
      padding: 0px;
      position: relative;
      text-decoration: rgb(255, 255, 255);
      text-rendering: optimizelegibility;
      touch-action: manipulation;
      transition: all 0.3s ease 0s;
      -moz-osx-font-smoothing: grayscale;
      &:hover {
        color: var(--accent);
      }
    }
  }
`;

export default function SidebarLinks({ children }) {
  return (
    <SidebarLinksStyles>
      <ul>{children}</ul>
    </SidebarLinksStyles>
  );
}

SidebarLinks.propTypes = {
  children: PropTypes.any,
};
