import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import CallToAction from '../CallToAction';

const SidebarContactStyles = styled.aside`
  margin-top: 60px;
  background: white;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.03) 4px 4px 10px 0px;
  border: 1px solid #f2f2f2;

  h2 {
    text-transform: uppercase;
    font-size: 16px;
  }

  p {
    box-sizing: border-box;
    color: rgb(85, 85, 85);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-optical-sizing: auto;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    text-rendering: optimizelegibility;
    vertical-align: baseline;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 15px;
  }
`;

export default function SidebarContact() {
  return (
    <SidebarContactStyles>
      <h2>How Can We Help?</h2>

      <p>
        Are you interested in finding out how we can make your project. Please
        contact us.
      </p>

      <div className="cta">
        <Link to="/contact">
          <CallToAction>Get In Touch</CallToAction>
        </Link>
      </div>
    </SidebarContactStyles>
  );
}
